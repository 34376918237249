


































import { Vue, Component, Prop } from "vue-property-decorator";
import { get, has } from "lodash";
import { ContactTypeId, CreateContact } from "@/entity/Contact";
import { CreateCompany } from "@/entity/Company";

@Component
export default class Results extends Vue {
  @Prop({ required: true, type: Object }) results!: any;

  fullAddress(entry: any): string {
    const street = get(entry, "tel:street[0]");
    const streetNo = get(entry, "tel:streetno[0]");
    const city = get(entry, "tel:city[0]");
    const zip = get(entry, "tel:zip[0]");
    const phone = this.getPhone(entry);

    return `
    ${street} ${streetNo}</br>
    ${city} ${zip}</br>
    ${phone}
    `;
  }

  buildContactObject(entry: any): Partial<CreateContact | CreateCompany> {
    return {
      name: get(entry, "tel:name[0]"),
      firstName: get(entry, "tel:firstname[0]"),
      lastName: get(entry, "tel:name[0]"),
      address: {
        address: `${get(entry, "tel:street[0]")} ${get(
          entry,
          "tel:streetno[0]"
        )}`,
        city: get(entry, "tel:city[0]"),
        postcode: get(entry, "tel:zip[0]"),
        country: this.getCountryId(entry),
      },
      phoneFixed: this.getPhone(entry),
      email: this.getEmail(entry),
      url: this.getWebsite(entry),
    };
  }

  getContactTypeId(entry: any): number {
    const type = get(entry, "tel:type[0]") as string;
    if (type === "Organisation") {
      return ContactTypeId.company;
    } else if (type === "Person") {
      return ContactTypeId.contact;
    }
    throw new Error("Unsupported contact type");
  }

  getTitle(entry: any): string {
    const name = get(entry, "title[0]._", "");
    const type = this.getContactType(entry);
    return `${name} - ${type}`;
  }

  getContactType(entry: any): string {
    const type = get(entry, "tel:type[0]") as string;
    if (type === "Organisation") {
      return this.$tc("company", 1);
    } else if (type === "Person") {
      return this.$tc("contact", 1);
    }
    return "";
  }

  getCountryId(entry: any): number | undefined {
    const country = get(entry, "tel:country[0]") as string | undefined;
    return country === "ch" ? 1 : undefined;
  }

  getWebsite(entry: any): string {
    const extra = get(entry, "tel:extra", []);
    const email = extra.find((item: any) => {
      return item.$.type === "website";
    });
    return email?._ ?? "";
  }

  getEmail(entry: any): string {
    const extra = get(entry, "tel:extra", []);
    const email = extra.find((item: any) => {
      return item.$.type === "email";
    });
    return email?._ ?? "";
  }

  getPhone(entry: any): string {
    const phone = get(entry, "tel:phone[0]");
    if (has(phone, "_")) {
      return phone._;
    }

    return phone;
  }

  getBranches(entry: any): string {
    return get(entry, "tel:category", []).join(", ");
  }

  handleOnClick(entry: any): void {
    const contact = this.buildContactObject(entry);
    this.$emit("select", {
      contact,
      type: this.getContactTypeId(entry),
    });
  }
}
