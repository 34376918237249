
































































import { Component, Mixins, Watch } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import RulesMixin from "@/mixins/RulesMixin";
import BaseForm from "@/components/form/BaseForm";
import SearchApiService from "@/services/SearchApiService";
import Results from "@/components/contact-from-search-api/Results.vue";

@Component({
  components: {
    FormCard,
    Results,
  },
})
export default class ContactSearchApiForm extends Mixins(BaseForm, RulesMixin) {
  searchName = "";
  searchAddress = "";
  page = 1;
  perPage = 5;
  totalItems = 0;
  feed: any | null = null;
  showEmptyMessage = false;

  get pos(): number {
    return (this.page - 1) * this.perPage;
  }

  get totalPages(): number {
    return Math.floor(this.totalItems / this.perPage);
  }

  get showPagination(): boolean {
    return Array.isArray(this.feed?.entry) && this.feed.entry?.length > 0;
  }

  handleOnKeydown(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      this.search();
    }
  }

  async search(): Promise<void> {
    try {
      this.showEmptyMessage = false;
      this.isSaving = true;
      const response = await SearchApiService.find(
        this.searchName,
        this.searchAddress,
        this.pos,
        this.perPage
      );
      this.feed = response.feed;
      this.totalItems = parseInt(response.feed["openSearch:totalResults"][0]);
    } finally {
      this.isSaving = false;
      if (this.totalItems === 0) {
        this.showEmptyMessage = true;
      }
    }
  }

  onEntrySelect(entry: any) {
    this.$emit("select", entry);
  }

  @Watch("page")
  onPageChange(): void {
    this.search();
  }
}
