















































































import {
  Component,
  Mixins,
  Prop,
  PropSync,
  Watch,
} from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import Contact, { SalutationForm } from "@/entity/Contact";
import { OptionItem } from "@/services/types";
import Company from "@/entity/Company";

@Component
export default class ContactFormContactInfo extends Mixins(RulesMixin) {
  @PropSync("salutationForm", { type: String }) salutationFormLocal!: string;
  @PropSync("entityId", { type: String }) contactId!: string;
  @PropSync("lastName", { type: String }) lastNameLocal!: string;
  @PropSync("firstName", { type: String }) firstNameLocal!: string;
  @PropSync("email", { type: String }) emailLocal!: string;
  @PropSync("phoneMobile", { type: String }) phoneMobileLocal!: string;
  @PropSync("phoneFixed", { type: String }) phoneFixedLocal!: string;
  @PropSync("companyId", { type: Number }) companyIdLocal!: number | null;
  @Prop() owners!: Contact[];

  @Prop({ required: false, default: true }) showCompany!: boolean;
  @Prop({ required: false, default: false }) companies?: Company[];
  @Prop({ type: Boolean, default: true }) showTitle!: boolean;
  foundContacts: any;
  showFoundContacts: any;
  company: Partial<Company> = {};

  get salutations(): OptionItem[] {
    return [
      {
        value: "",
        name: "",
      },
      {
        value: SalutationForm.MR,
        name: this.$tc("salutationNames.mr"),
      },
      {
        value: SalutationForm.MRS,
        name: this.$tc("salutationNames.mrs"),
      },
      {
        value: SalutationForm.FAMILY,
        name: this.$tc("salutationNames.family"),
      },
      {
        value: SalutationForm.MR_AND_MRS,
        name: this.$tc("salutationNames.mrAndMrs"),
      },
    ];
  }

  @Watch("firstName")
  onFirstNameChange(value: string) {
    const contactId = this.contactId;
    const lastName = this.lastNameLocal;
    const found = this.owners.filter(function (element: any) {
      if (contactId && contactId === element.id) return null;
      return (
        element.firstName.toLowerCase() +
          " " +
          element.lastName.toLowerCase() ===
        value.toLowerCase() + " " + lastName.toLowerCase()
      );
    });
    this.foundContacts = found;
    if (found.length > 0) {
      this.showFoundContacts = true;
    } else {
      this.showFoundContacts = false;
    }
  }

  @Watch("lastName")
  onLastNameChange(value: string) {
    const contactId = this.contactId;
    const firstName = this.firstNameLocal;
    const found = this.owners.filter(function (element: any) {
      if (contactId && contactId === element.id) return null;
      return (
        element.firstName.toLowerCase() +
          " " +
          element.lastName.toLowerCase() ===
        firstName.toLowerCase() + " " + value.toLowerCase()
      );
    });
    this.foundContacts = found;
    if (found.length > 0) {
      this.showFoundContacts = true;
    } else {
      this.showFoundContacts = false;
    }
  }
}
