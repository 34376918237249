












































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import { countryList } from "@/entity/Contact";

@Component
export default class ContactFormAddressInfo extends Mixins(RulesMixin) {
  countries = countryList;

  @PropSync("address", { type: String }) addressLocal!: string;
  @PropSync("postcode", { type: String }) postcodeLocal!: string;
  @PropSync("city", { type: String }) cityLocal!: string;
  @PropSync("countryId", { type: Number }) countryIdLocal!: number | null;
}
