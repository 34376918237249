



























































import { Component, Mixins, PropSync, Prop } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import Company from "@/entity/Company";

@Component
export default class ContactFormBusinessInfo extends Mixins(RulesMixin) {
  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  @Prop({ type: Boolean, required: false }) isEditing!: boolean;

  @PropSync("buyerTypeId", { type: Number }) buyerTypeIdLocal!: number | null;
  @PropSync("remarks", { type: String }) remarksLocal!: string;
  @PropSync("isBlocked", { type: Boolean }) isBlockedLocal!: boolean;
  @Prop({ required: false, default: true }) showCompany!: boolean;
  @Prop()
  companies: Company[] = [];
  @PropSync("companyId", { type: Number }) companyIdLocal!: number | null;
  fetchingCompanies = false;
}
