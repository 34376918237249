import Axios from "axios";
import xml2js from "xml2js";

const API_URL = "https://tel.search.ch/api/";
const KEY = "51f8874b2d40129a7dc8f6cdf58fd0d3";

export default class SearchApiService {
  public static async find(
    name: string,
    address: string,
    pos: number,
    perPage = 10
  ) {
    const response = await Axios.get(API_URL, {
      params: {
        was: name,
        wo: address.length > 0 ? address : undefined,
        key: KEY,
        pos: pos,
        maxnum: perPage,
      },
    });

    const parser = new xml2js.Parser({ trim: true });
    return parser.parseStringPromise(response.data);
  }
}
